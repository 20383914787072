import React, { useEffect, useState } from "react";
import Pagination from "react-bootstrap/Pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export default function Blog() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const [loading, setLoading] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);

  const [blog, setBlog] = useState([]);
  const [blogId, setBlogId] = useState("");

  const [edit, setEdit] = useState(false);
  const [nameEN, setNameEn] = useState("");
  const [nameAr, setNameAr] = useState("");
  const [nameEs, setNameEs] = useState("");
  const [nameRu, setNameRu] = useState("");
  const [nameIt, setNameIt] = useState("");
  const [link, setLink] = useState("");
  const [shortDescEN, setShortDescEN] = useState("");
  const [shortDescAr, setShortDescAr] = useState("");
  const [shortDescEs, setShortDescEs] = useState("");
  const [shortDescRu, setShortDescRu] = useState("");
  const [shortDescIt, setShortDescIt] = useState("");
  const [file, setFile] = useState("");




   function addBlog() {
    setLoadingAdd(true)
    let formData = new FormData();
    formData.append("name_en", nameEN);
    formData.append("name_ar", nameAr);
    formData.append("name_es", nameEs);
    formData.append("name_ru", nameRu);
    formData.append("name_it", nameIt);
    formData.append("shortDesc_en", shortDescEN);
    formData.append("shortDesc_ar", shortDescAr);
    formData.append("shortDesc_es", shortDescEs);
    formData.append("shortDesc_ru", shortDescRu);
    formData.append("shortDesc_it", shortDescIt);
    formData.append("image", file);
    formData.append("link", link);
    console.log(formData);
    axios.post(`https://mrolive-back-end-2c3o.onrender.com/api/v1/blogs`, formData , {
        headers: {
          Authorization: sessionStorage.getItem("userToken"),
        }
      }
    )
    .then((response) => {
      toast.success(response?.data?.msg)
      getBlog(currentPage, itemsPerPage);
      setTotalPages(response?.data?.paginationResult?.numberOfPages || 1);
      clearData()
    })
    .catch((err) => {
      toast.error(err?.response?.data?.msg);
    })
    .finally(() => {setLoadingAdd(false)})
  }

  async function getBlog(page = 1, perPage = 10) {
    setLoading(true);
    axios
      .get(`https://mrolive-back-end-2c3o.onrender.com/api/v1/blogs`, {
        headers: {
          lang: "both",
        },
        params: {
          page: page,
          limit: perPage,
        },
      })
      .then((response) => {
        setBlog(response?.data?.data);
        setTotalPages(response?.data?.paginationResult?.numberOfPages || 1);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.msg);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    getBlog(currentPage, itemsPerPage);
  }, [currentPage, itemsPerPage]);

  const getPageNumbers = () => {
    const pages = [];
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, startPage + 2);
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  
  function blogEdit() {
    setLoadingAdd(true)
    let formData = new FormData();
    formData.append("name_en", nameEN);
    formData.append("name_ar", nameAr);
    formData.append("name_es", nameEs);
    formData.append("name_ru", nameRu);
    formData.append("name_it", nameIt);
    formData.append("shortDesc_en", shortDescEN);
    formData.append("shortDesc_ar", shortDescAr);
    formData.append("shortDesc_es", shortDescEs);
    formData.append("shortDesc_ru", shortDescRu);
    formData.append("shortDesc_it", shortDescIt);
    file && formData.append("image", file);
    formData.append("link", link);
    axios.patch(`https://mrolive-back-end-2c3o.onrender.com/api/v1/blogs/${blogId}`, formData , {
        headers: {
          Authorization: sessionStorage.getItem("userToken"),
        }
      }
    )
    .then((response) => {
      toast.success(response?.data?.msg)
      getBlog(currentPage, itemsPerPage);
      setTotalPages(response?.data?.paginationResult?.numberOfPages || 1);
      clearData()
    })
    .catch((err) => {
      toast.error(err?.response?.data?.msg);
    })
    .finally(() => {setLoadingAdd(false)})
  }

  function clearData() {
    setNameAr("")
    setNameEn("")
    setNameEs("")
    setNameIt("")
    setNameRu("")
    setShortDescAr("")
    setShortDescEN("")
    setShortDescEs("")
    setShortDescIt("")
    setShortDescRu("")
    setFile(false)
  }


  async function deleteblog(id) {
      axios.delete(`https://mrolive-back-end-2c3o.onrender.com/api/v1/blogs/${id}` , {
        headers: {
          Authorization: sessionStorage.getItem("userToken"),
        }
      })
      .then((response)=>{
        toast.success(response?.data?.msg)
        getBlog(currentPage, itemsPerPage);
        setTotalPages(response?.data?.paginationResult?.numberOfPages || 1);
      }).catch((err)=>{
        toast.error(err?.response?.data?.msg)
      })
  }

  return (
    <>
      <ToastContainer />

      <div className="container p-5">
        <div className="row ">
          <div className="col-md-6">
            <label className="mt-3 pb-1">Name English :</label>
              <input
                type="text"
                className="form-control mb-2 py-2"
                placeholder="Enter English Name"
                name="name"
                id="demo"
                value={nameEN}
                onChange={(e) => {
                  setNameEn(e.target.value);
                }}
              />
          </div>
          <div className="col-md-6 ">
            <label className="mt-3 pb-1">Name Arabic :</label>
              <input
                type="text"
                className="form-control mb-2 py-2"
                placeholder="Enter Arabic Name"
                name="name"
                id="demo1"
                value={nameAr}
                onChange={(e) => {
                  setNameAr(e.target.value);
                }}
              />
          </div>
          <div className="col-md-6 ">
            <label className="mt-3 pb-1">Name Spain :</label>
              <input
                type="email"
                className="form-control mb-2 py-2"
                placeholder="Enter Spain Name"
                name="name"
                id="demo2"
                value={nameEs}
                onChange={(e) => {
                  setNameEs(e.target.value);
                }}
              />
            
          </div>
          <div className="col-md-6 ">
            <label className="mt-3 pb-1">Name Russia :</label>
              <input
                type="text"
                className="form-control mb-2 py-2"
                placeholder="Enter Russia Name"
                name="name"
                id="demo3"
                value={nameRu}
                onChange={(e) => {
                  setNameRu(e.target.value);
                }}
              />
  
          </div>
          <div className="col-md-6 ">
            <label className="mt-3 pb-1">Name Italy :</label>

              <input
                type="text"
                className="form-control mb-2 py-2"
                name="name"
                placeholder="Enter Italy Name"
                value={nameIt}
                onChange={(e) => {
                  setNameIt(e.target.value);
                }}
              />
          </div>
          <div className="col-md-6 ">
            <label className="mt-3 pb-1">Link :</label>
              <input
                type="text"
                className="form-control mb-2 py-2"
                name="name"
                placeholder="Enter Link"
                value={link}
                onChange={(e) => {
                  setLink(e.target.value);
                }}
              />
          </div>
          <div className="col-md-6">
            <label className="mt-3 pb-1">Description English :</label>
              <textarea
                type="text"
                className="form-control mb-2 py-2"
                placeholder="Enter English Description"
                name="name"
                id="demo"
                value={shortDescEN}
                onChange={(e) => {
                  setShortDescEN(e.target.value);
                }}
              />
          </div>
          <div className="col-md-6">
            <label className="mt-3 pb-1">Description Arabic :</label>
              <textarea
                type="text"
                className="form-control mb-2 py-2"
                placeholder="Enter Arabic Description"
                name="name"
                id="demo"
                value={shortDescAr}
                onChange={(e) => {
                  setShortDescAr(e.target.value);
                }}
              />
          </div>
          <div className="col-md-6">
            <label className="mt-3 pb-1">Description Spain :</label>
           
              <textarea
                type="text"
                className="form-control mb-2 py-2"
                placeholder="Enter Spain Description"
                name="name"
                id="demo"
                value={shortDescEs}
                onChange={(e) => {
                  setShortDescEs(e.target.value);
                }}
              />
          </div>
          <div className="col-md-6">
            <label className="mt-3 pb-1">Description Russia :</label>
              <textarea
                type="text"
                className="form-control mb-2 py-2"
                placeholder="Enter Russia Description"
                name="name"
                id="demo"
                value={shortDescRu}
                onChange={(e) => {
                  setShortDescRu(e.target.value);
                }}
              />
          </div>
          <div className="col-md-6">
            <label className="mt-3 pb-1">Description Italy :</label>
         
              <textarea
                type="text"
                className="form-control mb-2 py-2"
                placeholder="Enter Italy  Description"
                name="name"
                id="demo"
                value={shortDescIt}
                onChange={(e) => {
                  setShortDescIt(e.target.value);
                }}
              />
          </div>
          <div className="col-md-6">
            <label className="mt-3 pb-1">Image :</label>
            
              <input
                type="file"
                className="form-control mb-2 py-2"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
          </div>
          <div className="text-end">
         {edit ? <>
          <button onClick={()=>{blogEdit()}} className="btn btn-dark">
             {loadingAdd ? <i className="fa-solid fa-spinner"></i>   : "Edit"}
            </button>
            </> : <>
            <button onClick={()=>{addBlog()}} className="btn btn-dark">
             {loadingAdd ? <i className="fa-solid fa-spinner"></i>   : "Add Blog"}
            </button>
            </>}
          </div>
        </div>
      </div>

      <div className="container">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th >Name</th>
              <th>Description</th>
              <th>Image</th>
              <th>Link</th>
              <th>Link</th>
            </tr>
          </thead>
          <tbody>
            {blog?.map(
              (blog, index) => (
                console.log(blog),
                (
                  <tr key={index}>
                    <td>{blog?.name_en}</td>
                    <td>{blog?.shortDesc_en}</td>
                    <td>
                      <img
                        src={blog?.image}
                        alt="blog"
                        style={{
                          width: "50px",
                          height: "8vh",
                          borderRadius: "0.5rem",
                        }}
                      />
                    </td>
                    <td>
                      <a href={blog?.link} target="_blank">
                        Link
                      </a>
                    </td>
                    <td>
                      <button
                      className="btn bg-dark btn-dark"
                      onClick={() => {
                        setEdit(true);
                        setNameEn(blog?.name_en);
                        setNameAr(blog?.name_ar);
                        setNameIt(blog?.name_it);
                        setNameEs(blog?.name_es);
                        setNameRu(blog?.name_ru);
                        setShortDescAr(blog?.shortDesc_ar);
                        setShortDescEN(blog?.shortDesc_en);
                        setShortDescEs(blog?.shortDesc_es);
                        setShortDescIt(blog?.shortDesc_it);
                        setShortDescRu(blog?.shortDesc_ru);
                        setBlogId(blog?._id);
                        setLink(blog?.link);
                      }}
                    >
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button> 
                       <button
                  className="btn bg-dark btn-dark ms-2"
                  onClick={() => {
                    deleteblog(blog?._id)
                  }}
                >
                  <i className="fa-solid fa-trash"></i>
                </button> 
                    </td>
                  </tr>
                )
              )
            )}
          </tbody>
        </table>
      </div>
      {/* <div className="d-flex justify-content-center">
        <Pagination>
          <Pagination.First
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          />
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {getPageNumbers().map((pageNumber) => (
            <Pagination.Item
              key={pageNumber}
              active={pageNumber === currentPage}
              onClick={() => handlePageChange(pageNumber)}
            >
              {pageNumber}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
          <Pagination.Last
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div> */}
    </>
  );
}
