import axios from "axios";
import Joi from "joi";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App/App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Login({props}) {
  let navigate = useNavigate();
  const [errorList, setErrorList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const getUser = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  async function submitLogin(e) {
    e.preventDefault();
    setIsLoading(true);
    const validationResult = validateLoginForm(user);
    if (validationResult.error) {
      setIsLoading(false);
      setErrorList(validationResult.error.details);
    } else {
      axios.post(
          "https://mrolive-back-end-2c3o.onrender.com/api/v1/auth/admin/login",
          user
        ).then((response)=>{
         sessionStorage.setItem("userToken", response.data.token);
          toast.success(response.data.msg);
          // props.getUserData();
          navigate("/");
        }).catch((err)=>{
          toast.error(err.response?.data?.msg);

        }).finally(()=>{
          setIsLoading(false)
        })
    }
  }

  function validateLoginForm(user) {
    const schema = Joi.object({
      email: Joi.string()
        .email({ minDomainSegments: 2, tlds: { allow: ["com", "net"] } })
        .required()
        .label("Email"),
      password: Joi.string()
        .pattern(new RegExp(/^[a-zA-Z0-9]{3,30}$/))
        .required()
        .label("Password"),
    });
    return schema.validate(user, { abortEarly: false });
  }

  return (
    <div>
      <ToastContainer />
      <h1>Login Now</h1>
      {errorList.map((error, index) => (
        <div key={index} className="alert alert-danger">
          {error.message}
        </div>
      ))}
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="container-fluid">
        <form className="py-4 w-50" onSubmit={submitLogin}>
          <label htmlFor="email">
            <h4>Email:</h4>
          </label>
          <input
            type="email"
            onChange={getUser}
            id="email"
            className="my-3 form-control"
            name="email"
            style={{ backgroundColor: "green", color: "black" }}
          />

          <label htmlFor="password">
            <h4>Password:</h4>
          </label>
          <input
            type="password"
            onChange={getUser}
            id="password"
            className="my-3 form-control text-black"
            name="password"
            style={{ backgroundColor: "red", color: "black" }}
          />
          <button type="submit" className="btn btn-outline-success">
            {isLoading ? <i className="fas fa-spinner fa-spin"></i> : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
}
